import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import { Task, TaskList } from "../../types/taskTypes";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Stack,
} from "@mui/material";
import { SellOutlined } from "@mui/icons-material";
import { useState } from "react";
import ScoreChip from "../TaskComponents/ScoreChip";

interface TaskCardProps {
  task: Task;
}

function TaskCard({ task }: TaskCardProps) {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const navigateToTask = () => navigate(`/task/${task.id}`);

  return (
    <Card sx={{ minWidth: 275, height: "100%" }}>
      <CardActionArea onClick={navigateToTask}>
        <CardHeader
          title={task.title}
          subheader={task.name}
          action={<ScoreChip />}
        />
      </CardActionArea>

      <CardActions>
        <Button
          endIcon={<SellOutlined />}
          onClick={() => setExpanded(!expanded)}
        >
          Tags
        </Button>
      </CardActions>

      <Collapse in={expanded}>
        <CardContent>
          <Stack direction="row" sx={{ flexFlow: "wrap", gap: "4px 4px" }}>
            {task.tags.map((tag, index) => (
              <Chip label={tag} variant="outlined" size="small" key={index} />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function TaskListDisplay(props: TaskList) {
  return (
    <Grid container spacing={2} alignItems="stretch">
      {props.tasks.map((task) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={task.id.toString()}>
          <TaskCard task={task} />
        </Grid>
      ))}
    </Grid>
  );
}
