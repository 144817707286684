import { Box, Typography, Container, Stack, Grid } from "@mui/material";
import LogoOIA from "../assets/oialogo.png";
import LogoIOI from "../assets/ioilogo.png";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Container>
      <Stack marginTop={8} marginBottom={8} spacing={3}>
        <Typography component="h1" variant="h3">
          OIAJ: El juez de la Olimpíada Informática Argentina
        </Typography>
        <Typography variant="body1">
          El OIA Juez (OIAJ) es un sistema de evaluación automática en línea que
          permite el entrenamiento de los alumnos en programación, mediante el
          envío de soluciones en C++. Vea las{" "}
          <Link to="/preguntas-frecuentes">preguntas frecuentes</Link> si es su
          primera vez en el sitio.
        </Typography>
        <br />
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={LogoOIA} alt="Logo OIA" width="50%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={LogoIOI} alt="Logo IOI" width="50%" />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
