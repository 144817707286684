import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Grid, Paper, Skeleton, Snackbar, Tab } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import CodeEditor from "../components/TaskComponents/CodeEditor";
import SubmissionsComponent from "../components/TaskComponents/SubmissionsTable";
import TaskDescription from "../components/TaskComponents/TaskDescription";
import UploadCode from "../components/TaskComponents/UploadCode";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getTasks } from "../redux/slices/tasksSlice";
import { validateToken } from "../redux/slices/userSlice";
import LoginRequiredPath from "../components/LoginRequired";
import TagArray from "../components/TaskComponents/TagArray";
import KitsDisplay from "../components/TaskComponents/KitsDisplay";

export default function SingleTaskPage() {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();

  const [tabValue, setTabValue] = React.useState("0");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const currentTask = useAppSelector((state) =>
    state.tasks.tasks.find((task) => task.id === Number(taskId)),
  );

  React.useEffect(() => {
    dispatch(validateToken());
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (currentTask === undefined) dispatch(getTasks());
    //eslint-disable-next-line
  }, [currentTask]);

  const submissionSuccessCallback = () => {
    setSnackbarOpen(true);
    setTabValue("2");
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} md={6} key={"1"}>
        {currentTask !== undefined ? (
          <TaskDescription task={currentTask} />
        ) : (
          <Skeleton variant="rounded" height={800} />
        )}
      </Grid>

      <Grid item xs={12} md={6} key={"2"}>
        <Paper elevation={2}>
          <TabContext value={tabValue}>
            <TabList variant="scrollable" onChange={handleTabChange}>
              <Tab label="Escribir Código" value="0" />
              <Tab label="Subir Código" value="1" />
              <Tab label="Mis Envios" value="2" />
              <Tab label="Tags" value="3" />
              <Tab label="Kits" value="4" />
            </TabList>
            <TabPanel value="0">
              <LoginRequiredPath>
                <CodeEditor
                  taskId={currentTask?.id || -1}
                  submissionFormat={currentTask?.submission_format[0] || "a"}
                  submissionSuccessCallback={submissionSuccessCallback}
                />
              </LoginRequiredPath>
            </TabPanel>
            <TabPanel value="1">
              <LoginRequiredPath>
                <UploadCode
                  taskId={currentTask?.id || -1}
                  submissionFormat={currentTask?.submission_format || []}
                  submissionSuccessCallback={submissionSuccessCallback}
                />
              </LoginRequiredPath>
            </TabPanel>
            <TabPanel value="2">
              <LoginRequiredPath>
                <SubmissionsComponent taskId={taskId || "1"} />
              </LoginRequiredPath>
            </TabPanel>
            <TabPanel value="3">
              <TagArray tags={currentTask?.tags || []} />
            </TabPanel>
            <TabPanel value="4">
              {currentTask !== undefined ? (
                <KitsDisplay task={currentTask} />
              ) : (
                <></>
              )}
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>

      {/* A partir de acá se encuentran los snackbars de alertas */}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          ¡Problema enviado con éxito!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
