import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { NewSubmissionRequest } from "../../types/submissionTypes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { submitTask, clearStatus } from "../../redux/slices/submissionsSlice";

interface CodeEditorProps {
  taskId: Number;
  submissionFormat: string[];
  submissionSuccessCallback: () => void;
}

function UploadCode(props: CodeEditorProps) {
  const [files, setFiles] = React.useState<{ [key: string]: File | null }>({});
  const dispatch = useAppDispatch();

  const submitStatus = useAppSelector((state) => state.submissions.status);

  const onFileChange = (file: File | null, id: string) => {
    setFiles({ ...files, [id]: file });
  };

  const handleSubmit = async () => {
    const submitRequest: NewSubmissionRequest = {
      taskId: props.taskId,
      submissionFiles: {},
    };

    for (const id of props.submissionFormat) {
      if (files[id] === null) return;
      const content: string = (await files[id]?.text()) || "";
      submitRequest.submissionFiles[id] = btoa(content);
    }

    dispatch(submitTask(submitRequest));
  };

  React.useEffect(() => {
    if (submitStatus === "succededNewSubmission") {
      dispatch(clearStatus({}));
      props.submissionSuccessCallback();
    }
    // eslint-disable-next-line
  }, [submitStatus]);

  const checkIfAllFilesSelected = () => {
    for (const id of props.submissionFormat) if (!files[id]) return false;
    return true;
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h5">Subir Archivos:</Typography>
        <br />
        {props.submissionFormat.map((id, index) => (
          <>
            <Typography variant="body1">{id}</Typography>
            <MuiFileInput
              value={files[id] || null}
              onChange={(file) => onFileChange(file, id)}
            />
          </>
        ))}
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={!checkIfAllFilesSelected()}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </Stack>
    </>
  );
}

export default UploadCode;
