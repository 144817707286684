import React from "react";
import { getTasks } from "../redux/slices/tasksSlice";
import { useAppSelector, useAppDispatch } from "../hooks";
import { Task, compareTasks } from "../types/taskTypes";
import Box from "@mui/material/Box";
import TaskListDisplay from "../components/TaskListComponents/TaskListDisplay";
import Container from "@mui/material/Container";
import SearchBar from "../components/TaskListComponents/SearchBar";
import { useSearchParams } from "react-router-dom";

export default function TasksPage() {
  const dispatch = useAppDispatch();
  const tasks = useAppSelector((state) => state.tasks.tasks);
  const [searchParams] = useSearchParams();
  const [filteredTasks, setFilteredTasks] = React.useState<Task[]>(
    [...tasks].sort(compareTasks),
  );

  React.useEffect(() => {
    dispatch(getTasks());
  }, [dispatch]);

  React.useEffect(() => {
    const filterList: string[] = [];
    const tagParamList: string[] = ["tema", "certamen", "año"];
    const textSearchValue: string =
      (searchParams.get("busqueda") as string) || "";

    for (const param of tagParamList) {
      if (searchParams.has(param)) {
        filterList.push(param + ":" + searchParams.get(param));
      }
    }

    const filtered: Task[] = tasks.filter((task) => {
      return (
        filterList.every((item) => task.tags.includes(item)) &&
        (task.name.toLowerCase().includes(textSearchValue.toLowerCase()) ||
          task.title.toLowerCase().includes(textSearchValue.toLowerCase()))
      );
    });

    filtered.sort(compareTasks);
    setFilteredTasks(filtered);
  }, [searchParams, tasks]);

  return (
    <Container>
      <SearchBar tasks={tasks} />
      <Box marginBottom={10} sx={{ flexGrow: 1 }}>
        <TaskListDisplay tasks={filteredTasks} />
      </Box>
    </Container>
  );
}
