import { Task } from "../../types/taskTypes";
import { Paper, Stack, Typography } from "@mui/material";
import { backendUrl } from "../../config";

interface TaskDescriptionProps {
  task: Task;
}

function TaskDescription({ task }: TaskDescriptionProps) {
  return (
    <Paper elevation={3}>
      <Stack padding={2} spacing={2}>
        <Typography variant="h4">{task.title}</Typography>
        <Typography variant="h5">{task.name}</Typography>
        <embed
          style={{ height: "75vh" }}
          width="100%"
          src={`${backendUrl}/task/statement/${task.id}`}
          type="application/pdf"
        />
      </Stack>
    </Paper>
  );
}

export default TaskDescription;
