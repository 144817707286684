import React from "react";
import { Submission } from "../../types/submissionTypes";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { getTaskSubmissions } from "../../redux/slices/submissionsSlice";
import {
  TableCell,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import ScoreChip from "./ScoreChip";
import { useTheme } from "@mui/material/styles";
import SubmissionDetailTable from "./SubmissionDetailTable";

function SubmissionDialog({
  submission,
  isOpen,
  handleClose,
}: {
  submission: Submission | null;
  isOpen: boolean;
  handleClose: () => void;
}) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (submission === null || submission?.result === null) return <></>;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      fullScreen={isFullScreen}
      fullWidth
    >
      <DialogTitle>Detalles del Envío #{submission.id.valueOf()}</DialogTitle>
      <DialogContent>
        <SubmissionDetailTable result={submission.result} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

const getSubmissionStatus = (status: string) => {
  switch (status) {
    case "compiling":
      return "Compilando...";
    case "evaluating":
      return "Evaluando...";
    case "scored":
      return "Evaluado";
    case "compilation_failed":
      return "Error de compilación";
    default:
      return status;
  }
};

function SubmissionRow({
  submission,
  handleOpen,
}: {
  submission: Submission;
  handleOpen: (submission: Submission) => void;
}) {
  const timestamp = new Date(submission.timestamp);
  const submissionDate = `${timestamp.toLocaleDateString()} - ${timestamp.toLocaleTimeString()}`;

  return (
    <TableRow>
      <TableCell>{submissionDate}</TableCell>
      <TableCell>{getSubmissionStatus(submission.submission_status)}</TableCell>
      <TableCell>
        <ScoreChip
          score={submission.result?.score.score}
          maxScore={submission.result?.score.max_score}
        />
      </TableCell>
      <TableCell>
        <Button variant="contained" onClick={() => handleOpen(submission)}>
          Ver
        </Button>
      </TableCell>
    </TableRow>
  );
}

function SubmissionsTable({ submissions }: { submissions: Submission[] }) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedSubmission, setSelectedSubmission] =
    React.useState<Submission | null>(null);

  const handleOpen = (submission: Submission) => {
    setSelectedSubmission(submission);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const arrayForSort = [...submissions];

  const sortedSubmissions = arrayForSort.sort(
    (a, b) => b.id.valueOf() - a.id.valueOf(),
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h5">Mis Envíos</Typography>
      <Typography variant="body1">
        Los resultados se irán actualizando a medida que se vayan evaluando. No
        es necesario actualizar la pagina.
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Puntaje</TableCell>
              <TableCell>Detalles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSubmissions.map((submission, index) => (
              <SubmissionRow
                submission={submission}
                handleOpen={handleOpen}
                key={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SubmissionDialog
        submission={selectedSubmission}
        isOpen={open}
        handleClose={handleClose}
      />
    </Stack>
  );
}

interface SubmissionsComponentProps {
  taskId: string;
}

export default function SubmissionsComponent({
  taskId,
}: SubmissionsComponentProps) {
  const currentSumbissions = useAppSelector(
    (state) => state.submissions.submissions,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getTaskSubmissions({ taskId: Number(taskId) }));

    const intervalId = setInterval(() => {
      dispatch(getTaskSubmissions({ taskId: Number(taskId) }));
    }, 3000);

    return () => clearInterval(intervalId);

    //eslint-disable-next-line
  }, []);

  return <SubmissionsTable submissions={currentSumbissions} />;
}
