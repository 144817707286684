import { Task } from "../../types/taskTypes";
import { Stack, Button, Typography } from "@mui/material";
import { backendUrl } from "../../config";

interface Props {
  task: Task;
}

function KitsDisplay({ task }: Props) {
  const taskAttachmentURL = `${backendUrl}/task/attachment?task_id=${task.id}&filename=`;
  const downloadAttachment = (attachment: string) => {
    window.open(taskAttachmentURL + attachment);
  };

  const attachmentButtonList = task.attachments.map((attachment: string) => (
    <Button variant="contained" onClick={() => downloadAttachment(attachment)}>
      Descargar {attachment}
    </Button>
  ));

  return (
    <Stack padding={2} spacing={2}>
      {task.attachments.length > 0 ? (
        attachmentButtonList
      ) : (
        <Typography>Este problema no tiene kits descargables.</Typography>
      )}
    </Stack>
  );
}

export default KitsDisplay;
