import LogoOIA from "../assets/oialogo.png";
import MenuIcon from "@mui/icons-material/Menu";

import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../hooks";
import { signOut } from "../redux/slices/userSlice";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

interface Props {
  window?: () => Window;
}

const drawerWidth = 300;
const navItems = [
  {
    text: "Problemas",
    path: "/tasks",
  },
  {
    text: "FAQ",
    path: "/preguntas-frecuentes",
  },
];

const OIAJLogo = () => (
  <Stack
    component={Link}
    to="/"
    direction="row"
    alignItems="flex-end"
    color="inherit"
    sx={{ textDecoration: "none" }}
  >
    <img src={LogoOIA} alt="Logo OIAJuez" height={40} />
    <Typography variant="h5" marginLeft={2}>
      OIA Juez
    </Typography>
  </Stack>
);

function DrawerAppBar({ window }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const userState = useAppSelector((state) => state.user);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleLoginSignout = () => {
    if (userState.isLoggedIn) dispatch(signOut({}));
    else navigate("/login");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        OIA Juez
      </Typography>

      <Divider />

      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.text}
            onClick={() => navigate(item.path)}
            disablePadding
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem
          key="loginSignOut"
          onClick={() => handleLoginSignout()}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText
              primary={
                userState.isLoggedIn ? "Cerrar sesión" : "Iniciar sesión"
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <OIAJLogo />

          <Box sx={{ flexGrow: 1 }}></Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                onClick={() => navigate(item.path)}
                sx={{ color: "#fff", textTransform: "none", fontSize: 18 }}
              >
                {item.text}
              </Button>
            ))}
            <Button
              key="loginSignOut"
              onClick={() => handleLoginSignout()}
              sx={{ color: "#fff", textTransform: "none", fontSize: 18 }}
            >
              {userState.isLoggedIn ? "Cerrar sesión" : "Iniciar sesión"}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
