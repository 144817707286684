import React from "react";
import ReactDOM from "react-dom/client";
//import App from './App';
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./redux/store";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppBarWrapper from "./components/AppBarWrapper";
import CheckCredentials from "./components/CheckCredentials";
import Login from "./pages/Login";

import { basename } from "./config";
import Signup from "./pages/Signup";
import SingleTaskPage from "./pages/SingleTaskPage";
import Home from "./pages/Home";
import FAQ from "./pages/FrequentQuestions";
import TasksPage from "./pages/TasksPage";
import { yellow } from "@mui/material/colors";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

//dark mode
const theme = createTheme({
  palette: {
    mode: "light",
    warning: yellow,
  },
});

const router = createBrowserRouter(
  [
    { path: "/", element: <AppBarWrapper children={<Home />} /> },
    {
      path: "/preguntas-frecuentes",
      element: <AppBarWrapper children={<FAQ />} />,
    },
    { path: "/login", element: <AppBarWrapper children={<Login />} /> },
    { path: "/tasks", element: <AppBarWrapper children={<TasksPage />} /> },
    {
      path: "/task/:taskId",
      element: <AppBarWrapper children={<SingleTaskPage />} />,
    },
    { path: "/signup", element: <AppBarWrapper children={<Signup />} /> },
  ],
  { basename: basename },
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CheckCredentials />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
