import react from "react";
import { validateToken } from "../redux/slices/userSlice";
import { useAppDispatch } from "../hooks";

export default function CheckCredentials() {
  const dispatch = useAppDispatch();

  react.useEffect(() => {
    dispatch(validateToken());
    // eslint-disable-next-line
  }, []);

  return <></>;
}
