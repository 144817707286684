import { backendUrl } from "../../config";
import { TaskList, TaskSlice } from "../../types/taskTypes";
import {
  SliceCaseReducers,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export const getTasks = createAsyncThunk(
  "tasks/get",
  async (arg: void, thunkApi): Promise<TaskList> => {
    const response = await fetch(backendUrl + "/task/get", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: "{}",
    });

    if (response.status !== 200) throw new Error("Error desconocido");
    const tasks: TaskList = await response.json();
    return tasks;
  },
);

export const userSlice = createSlice<
  TaskSlice,
  SliceCaseReducers<TaskSlice>,
  "tasks"
>({
  name: "tasks",
  initialState: {
    status: "idle",
    errorMessage: "",
    tasks: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.fulfilled, (state, action) => {
        state.status = "succededGetTasks";
        state.tasks = action.payload.tasks;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.status = "failedGetTasks";
        state.errorMessage = action.error.message || "";
      })
      .addMatcher(
        (action) =>
          action.type.endsWith("pending") && action.type.startsWith("tasks"),
        (state, action) => {
          state.status = "loading";
        },
      );
  },
});

export default userSlice.reducer;
