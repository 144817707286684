import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { Button, Typography } from "@mui/material";

interface LoginRequiredPathProps {
  children: React.ReactNode;
}

function LoginRequiredPath(props: LoginRequiredPathProps): JSX.Element {
  const navigate = useNavigate();

  const isUserLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  if (isUserLoggedIn) return <> {props.children} </>;

  return (
    <>
      <Typography>
        Para poder acceder a esta funcionalidad, debes iniciar sesión.
      </Typography>
      <br />
      <Button variant="contained" fullWidth onClick={() => navigate("/login")}>
        Iniciar sesión
      </Button>
    </>
  );
}

export default LoginRequiredPath;
