import { backendUrl } from "../../config";
import {
  UserData,
  LoginData,
  SignUpData,
  PrimitiveUserData,
} from "../../types/userTypes";
import {
  SliceCaseReducers,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export const validateToken = createAsyncThunk(
  "user/retrieve",
  async (arg: void, thunkApi): Promise<UserData> => {
    const credentials: string | null = localStorage.getItem("credentials");
    if (credentials == null) throw new Error("No se encontraron creedenciales");
    const jsonCredentials: UserData = JSON.parse(credentials);

    const response = await fetch(backendUrl + "/token/validate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jsonCredentials.token,
      },
      body: JSON.stringify({ user_id: jsonCredentials.userId }),
    });

    if (response.status === 401)
      throw new Error("Creedenciales guardadas incorrectas");
    if (response.status !== 200) throw new Error("Error desconocido");
    return jsonCredentials;
  },
);

export const signIn = createAsyncThunk(
  "user/login",
  async (arg: LoginData, thunkApi): Promise<PrimitiveUserData> => {
    const response = await fetch(backendUrl + "/user/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arg),
    });

    if (response.status === 401) {
      throw new Error("Usuario y/o contraseña incorrectos");
    }
    if (response.status !== 200) throw new Error("Error desconocido");
    const data: PrimitiveUserData = await response.json();
    return data;
  },
);

export const signUp = createAsyncThunk(
  "user/signup",
  async (arg: SignUpData, thunkApi): Promise<PrimitiveUserData> => {
    const response = await fetch(backendUrl + "/user/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arg),
    });

    if (response.status === 500)
      throw new Error(
        "El usuario ya se encuentra registrado en la base de datos",
      );
    if (response.status !== 200) throw new Error("Error desconocido");
    const data: PrimitiveUserData = await response.json();
    return data;
  },
);

export const userSlice = createSlice<
  UserData,
  SliceCaseReducers<UserData>,
  "user"
>({
  name: "user",
  initialState: {
    isLoggedIn: false,
    status: "idle",
    errorMessage: "",
    username: "",
    userId: -1,
    token: "",
  },
  reducers: {
    clearStatus: (state) => {
      state.status = "";
    },
    signOut: (state) => {
      localStorage.removeItem("credentials");
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = "succededSignIn";
        state.username = action.meta.arg.username;
        state.token = action.payload.token;
        state.userId = action.payload.user_id;
        localStorage.setItem("credentials", JSON.stringify(state));
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = "succededSignUp";
        state.username = action.meta.arg.username;
        state.token = action.payload.token;
        state.userId = action.payload.user_id;
        localStorage.setItem("credentials", JSON.stringify(state));
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = "succededValidateToken";
        state.username = action.payload.username;
        state.token = action.payload.token;
        state.userId = action.payload.userId;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.status = "failedSignIn";
        state.errorMessage = action.error.message || "";
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.status = "failedSignUp";
        state.errorMessage = action.error.message || "";
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.status = "failedValidateToken";
        state.errorMessage = action.error.message || "";
      })
      .addMatcher(
        (action) =>
          action.type.endsWith("pending") && action.type.startsWith("user"),
        (state, action) => {
          state.status = "loading";
        },
      );
  },
});

export default userSlice.reducer;
export const { clearStatus, signOut } = userSlice.actions;
