import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import tasksSlice from "./slices/tasksSlice";
import submissionsSlice from "./slices/submissionsSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    tasks: tasksSlice,
    submissions: submissionsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
