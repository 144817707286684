import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

interface TagArrayProps {
  tags: string[];
}

function TagArray(props: TagArrayProps) {
  return (
    <Stack direction="row" spacing={1}>
      {props.tags.map((tag) => (
        <Chip label={tag} key={tag} color="primary" />
      ))}
    </Stack>
  );
}

export default TagArray;
