import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  SubmissionResult,
  Subtask,
  Testcase,
} from "../../types/submissionTypes";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ScoreChip from "./ScoreChip";
import { useState } from "react";

const formatAsSeconds = (time: Number) => `${time.toFixed(3)}s`;
const formatAsMB = (usage: Number) =>
  `${(usage.valueOf() / (1024 * 1024)).toFixed(3)}MB`;

function TestcaseTable({ testcases }: { testcases: Testcase[] }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Caso</TableCell>
          <TableCell>Tiempo usado</TableCell>
          <TableCell>Memoria usada</TableCell>
          <TableCell>Veredicto</TableCell>
          <TableCell>Puntaje</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {testcases.map((testcase, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{formatAsSeconds(testcase.execution_time)}</TableCell>
            <TableCell>{formatAsMB(testcase.memory_usage)}</TableCell>
            <TableCell>{testcase.message}</TableCell>
            <TableCell>
              <ScoreChip
                score={testcase.score.score}
                maxScore={testcase.score.max_score}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function SubtaskRow({
  subtask,
  testcases,
}: {
  subtask: Subtask;
  testcases: Testcase[];
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxTime = Math.max(
    ...testcases.map((testcase) => testcase.execution_time.valueOf()),
  );
  const maxMemoryUsage = Math.max(
    ...testcases.map((testcase) => testcase.memory_usage.valueOf()),
  );

  return (
    <>
      <TableRow sx={{ "& > *": { borderTop: "unset", borderBottom: "unset" } }}>
        <TableCell>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{subtask.subtask}</TableCell>
        <TableCell>{formatAsSeconds(maxTime)}</TableCell>
        <TableCell>{formatAsMB(maxMemoryUsage)}</TableCell>
        <TableCell>
          <ScoreChip
            score={subtask.score.score}
            maxScore={subtask.score.max_score}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={isExpanded} unmountOnExit>
            <Box margin={1}>
              <TestcaseTable testcases={testcases} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function SubmissionDetailTable({
  result,
}: {
  result: SubmissionResult;
}) {
  const nameToTestcase = new Map<string, Testcase>();
  for (const testcase of result.testcases) {
    nameToTestcase.set(testcase.testcase, testcase);
  }

  return (
    <Table width="lg" aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Subtarea</TableCell>
          <TableCell>Tiempo usado</TableCell>
          <TableCell>Memoria usada</TableCell>
          <TableCell>Puntaje</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {result.subtasks.map((subtask, index) => (
          <SubtaskRow
            subtask={subtask}
            testcases={
              subtask.testcases
                .map((name) => nameToTestcase.get(name))
                .filter((x) => x !== undefined) as Testcase[]
            }
            key={index}
          />
        ))}
      </TableBody>
    </Table>
  );
}
