import React from "react";

import TextField from "@mui/material/TextField";
import { Task, TaskList } from "../../types/taskTypes";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";

interface SearchDropdownProps {
  label: string;
  emptyValue: string;
  options: string[];
  id: string;
}

function SearchDropdown(props: SearchDropdownProps) {
  const [currentValue, setCurrentValue] = React.useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const searchParam: string = searchParams.get(props.id) as string;
    if (searchParams.has(props.id)) {
      setCurrentValue(searchParam);
    }
  }, [searchParams, props.id]);

  React.useEffect(() => {
    if (currentValue === "") searchParams.delete(props.id);
    else searchParams.set(props.id, currentValue);
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <FormControl fullWidth>
      <InputLabel id={"select-" + props.id + "-label"}>
        {props.label}
      </InputLabel>
      <Select
        id={"select-" + props.id}
        labelId={"select-" + props.id + "-label"}
        value={currentValue}
        label={props.label}
        onChange={(e) => setCurrentValue(e.target.value)}
      >
        <MenuItem value={""} key={""}>
          {props.emptyValue}
        </MenuItem>

        {props.options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface SearchBoxParams {
  label: string;
  id: string;
}

function SearchBox(props: SearchBoxParams) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentValue, setCurrentValue] = React.useState<string>("");

  React.useEffect(() => {
    const searchParam: string = searchParams.get(props.id) as string;
    if (searchParams.has(props.id)) {
      setCurrentValue(searchParam);
    }
  }, [searchParams, props.id]);

  React.useEffect(() => {
    if (currentValue === "") searchParams.delete(props.id);
    else searchParams.set(props.id, currentValue);
    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <TextField
      fullWidth
      id={props.id}
      label={props.label}
      variant="outlined"
      onChange={(e) => setCurrentValue(e.target.value)}
      value={currentValue}
    />
  );
}

function filterTags(tagBeginsWith: string, tasks: Task[]): string[] {
  let set: Set<string> = new Set();

  tasks.forEach((task: Task) => {
    task.tags.forEach((tag: String) => {
      if (tag.startsWith(tagBeginsWith)) {
        set.add(tag.replace(tagBeginsWith, ""));
      }
    });
  });

  return Array.from(set).sort();
}

export default function SearchBar(props: TaskList) {
  const [yearList, setYearList] = React.useState<string[]>([]);
  const [certamenList, setCertamenList] = React.useState<string[]>([]);
  const [categoryList, setCategoryList] = React.useState<string[]>([]);

  React.useEffect(() => {
    setYearList(filterTags("año:", props.tasks).reverse());
    setCertamenList(filterTags("certamen:", props.tasks));
    setCategoryList(filterTags("tema:", props.tasks));
  }, [props.tasks]);

  return (
    <Grid container marginTop={5} marginBottom={5} spacing={2}>
      <Grid item xs={12}>
        <SearchBox id="busqueda" label="Buscar problema" />
      </Grid>
      <Grid item xs={12} md={4}>
        <SearchDropdown
          label="Año"
          id="año"
          emptyValue="Todos los años"
          options={yearList}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SearchDropdown
          label="Certamen"
          id="certamen"
          emptyValue="Todos los certamenes"
          options={certamenList}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SearchDropdown
          label="Tema"
          id="tema"
          emptyValue="Todos los temas"
          options={categoryList}
        />
      </Grid>
    </Grid>
  );
}
