import { Chip } from "@mui/material";

interface ScoreDisplayProps {
  score?: Number;
  maxScore?: Number;
}

export default function ScoreChip({ score, maxScore }: ScoreDisplayProps) {
  if (score === undefined) return <></>;
  if (maxScore === undefined) maxScore = 100;

  const color =
    score === 0 ? "error" : score === maxScore ? "success" : "warning";
  return (
    <Chip label={`${score}/${maxScore}`} color={color} sx={{ width: "76px" }} />
  );
}
