import React from "react";
import {
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface QuestionProps {
  title: string;
  children: React.ReactNode;
}

function Question({ title, children }: QuestionProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1">{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default function FAQ() {
  return (
    <Container maxWidth="md">
      <Typography
        variant="h3"
        component="h1"
        sx={{ mt: 4, mb: 2 }}
        paddingBottom={3}
      >
        Preguntas Frecuentes
      </Typography>
      <Question title="¿Cualquiera puede utilizar el OIAJ?">
        ¡Sí! Cualquiera puede registrarse libremente, con solo seleccionar{" "}
        <cite>Iniciar Sesión</cite> en la barra superior, y luego de completar
        los datos, se puede ingresar y salir del sistema a través de la
        correspondiente opción.
      </Question>
      <Question title="Ya me registré e ingresé. ¿Ahora qué hago?">
        Recomendamos enviar como primer problema el problema{" "}
        <Link to={"/task/5"}>A+B</Link>, para asegurarse de que todo el sistema
        funciona correctamente y de que se entiende el mecanismo para enviar las
        soluciones. En dicho problema, se debe leer y escribir a la{" "}
        <strong>entrada y salida estándar</strong> (lo que normalmente es{" "}
        <q>leer del teclado</q> y <q>escribir a la pantalla</q>). <br />
        En cada problema, el enunciado especificará cómo se deben leer y
        escribir los datos: podría leerse y escribirse de un{" "}
        <strong>archivo</strong>, o bien se podría indicar una{" "}
        <strong>función</strong> a implementar, que ya reciba los datos y
        devuelva el resultado: en dichos problemas, <strong>no</strong> se debe
        incluir una función <strong>main</strong>. En estos casos, se proveerán{" "}
        <strong>adjuntos</strong> además del enunciado, con un ejemplo
        minimalista de la función que se debe implementar.
      </Question>
      <Question title="¿Por dónde comienzo si no tengo mucha experiencia en la programación competitiva?">
        Dentro del archivo de enunciados, es posible buscar problemas por{" "}
        <Link to={"/tasks"}>categoría</Link>. Estas categorías generalmente se
        corresponden con las técnicas que podrían utilizarse para resolver los
        problemas, y existe también una categoría principiante, con los
        problemas del sitio más adecuados para los que están comenzando.
      </Question>
      <Question title="Olvidé mi contraseña y no puedo ingresar. ¿Qué hago?">
        Contactarse con el administrador del sitio para reiniciar la contraseña:
        oiajuez@gmail.com
      </Question>
      <Question title="¿En qué lenguajes se pueden enviar los problemas?">
        En esta nueva versión del Juez solo se puede realizar envíos en C++11.
        Por eso, no te dará opción de elegir en qué lenguaje enviar.
        Proximamente agregaremos nuevos lenguajes según los habilitados en
        OIA/IOI.
      </Question>
      <Question title="Resolví un problema, pero me pregunto si se podrá hacer más fácil o mejor. ¿Puedo ver la solución de otros usuarios?">
        Actualmente, no es posible ver envíos de otros usuarios. ¡Proximamente
        agregaremos esa funcionalidad!
      </Question>
      <Question title="¿Los puntajes que obtengo en el juez online son los puntajes reales que hubiera obtenido en la competencia real donde se utilizó el problema?">
        <strong>No</strong>, especialmente para los problemas de las
        competencias más viejas. Hay varios motivos por los cuales esto no
        ocurre, <strong>algunos</strong> de los cuales son:
        <ul>
          <li>
            Los <strong>casos de prueba</strong> pueden ser completamente
            diferentes. Esto es especialmente cierto para las competencias más
            antiguas. También se podrían haber agregado casos adicionales al
            juez online, o incluso quitado o modificado los existentes.
          </li>
          <li>
            Los <strong>puntajes</strong> asignados a cada caso de prueba pueden
            ser diferentes.{" "}
          </li>
          <li>
            Los <strong>tiempos de ejecución</strong> generalmente no son
            iguales a los usados en la prueba real correspondiente, y aunque lo
            fueran, generalmente se utilizan distintas versiones de los
            compiladores, y el hardware utilizado es diferente, con lo cual los
            tiempos no son comparables.
          </li>
          <li>
            En el caso del OIAJ, la evaluación es realizada{" "}
            <strong>de manera completamente automática</strong> por el servidor,
            sin intervención humana. En una prueba real, los jurados pueden
            revisar el código fuente de los participantes, y por ejemplo,
            asignar cero puntos a soluciones que no intentan resolver el
            problema, y que únicamente imprimen la salida del caso de ejemplo, o
            imprimen siempre <q>Imposible</q>.
          </li>
        </ul>
      </Question>
      <Question title="Obtengo cero puntos, pero creo que mi programa debería resolver al menos el ejemplo, o al menos los casos más chicos. ¿Por qué puede ser?">
        El motivo más común para esto es no estar leyendo y escribiendo la
        entrada y salida de la forma indicada en el enunciado (por ejemplo,
        utilizar entrada y salida estándar, cuando se debe usar archivos{" "}
        <q>.in</q> y <q>.out</q>).
      </Question>
    </Container>
  );
}
