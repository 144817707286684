import { RootState } from "../store";
import { backendUrl } from "../../config";
import {
  SubmissionSlice,
  TaskSubmissionRequest,
  NewSubmissionRequest,
  SingleSubmissionRequest,
  Submissions,
  Submission,
} from "../../types/submissionTypes";
import {
  SliceCaseReducers,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export const getTaskSubmissions = createAsyncThunk<
  Submission[],
  TaskSubmissionRequest,
  { state: RootState }
>("submissions/get", async (arg, thunkApi) => {
  const response = await fetch(backendUrl + "/submissions/get", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + thunkApi.getState().user.token,
    },
    body: JSON.stringify({
      user_id: thunkApi.getState().user.userId,
      task_id: arg.taskId,
    }),
  });

  if (response.status === 401)
    throw new Error("Creedenciales guardadas incorrectas");
  if (response.status !== 200) throw new Error("Error desconocido");
  const responseJson: Submissions = await response.json();

  return responseJson.submissions;
});

export const getSingleSubmission = createAsyncThunk<
  Submission,
  SingleSubmissionRequest,
  { state: RootState }
>("submissions/getSingle", async (arg, thunkApi) => {
  const response = await fetch(backendUrl + "/submissions/get/single", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + thunkApi.getState().user.token,
    },
    body: JSON.stringify({
      submission_id: arg.submissionId,
    }),
  });

  if (response.status === 401)
    throw new Error("Creedenciales guardadas incorrectas");
  if (response.status !== 200) throw new Error("Error desconocido");
  const responseJson: Submission = await response.json();

  return responseJson;
});

export const submitTask = createAsyncThunk<
  void,
  NewSubmissionRequest,
  { state: RootState }
>("submissions/new", async (arg, thunkApi) => {
  const response = await fetch(backendUrl + "/submission/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + thunkApi.getState().user.token,
    },
    body: JSON.stringify({
      task_id: arg.taskId,
      user_id: thunkApi.getState().user.userId,
      sources: arg.submissionFiles,
    }),
  });

  if (response.status === 401)
    throw new Error("Creedenciales guardadas incorrectas");
  if (response.status !== 200) throw new Error("Error desconocido");
});

export const userSlice = createSlice<
  SubmissionSlice,
  SliceCaseReducers<SubmissionSlice>,
  "submissions"
>({
  name: "submissions",
  initialState: {
    submissions: [],
    status: "",
    errorMessage: "",
  },
  reducers: {
    clearStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskSubmissions.fulfilled, (state, action) => {
        state.status = "succededGetTaskSubmissions";
        state.submissions = action.payload;
      })
      .addCase(getSingleSubmission.fulfilled, (state, action) => {
        state.status = "succededGetSingleSubmission";
        const idx: number = state.submissions.findIndex(
          (e) => e.id === action.payload.id,
        );
        if (idx === -1) state.submissions = [action.payload];
        else state.submissions[idx] = action.payload;
      })
      .addCase(submitTask.fulfilled, (state, action) => {
        state.status = "succededNewSubmission";
      })
      .addCase(getTaskSubmissions.rejected, (state, action) => {
        state.status = "failedGetTaskSubmissions";
        state.errorMessage = action.error.message || "";
      })
      .addCase(getSingleSubmission.rejected, (state, action) => {
        state.status = "failedGetSingleSubmission";
        state.errorMessage = action.error.message || "";
      })
      .addCase(submitTask.rejected, (state, action) => {
        state.status = "failedNewSubmission";
        state.errorMessage = action.error.message || "";
      })
      .addMatcher(
        (action) =>
          action.type.endsWith("pending") &&
          action.type.startsWith("submissions"),
        (state, action) => {
          state.status = "loading";
        },
      );
  },
});

export default userSlice.reducer;
export const { clearStatus } = userSlice.actions;
