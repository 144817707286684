import AppBar from "./AppBar";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";

interface AppBarWrapperProps {
  children: React.ReactNode;
}

function AppBarWrapper(props: AppBarWrapperProps) {
  return (
    <>
      <AppBar />
      <Box component="main" sx={{ p: 2 }}>
        <Toolbar />
        {props.children}
      </Box>
    </>
  );
}

export default AppBarWrapper;
