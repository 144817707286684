import React from "react";
import { Box, Button } from "@mui/material";
import Editor from "@monaco-editor/react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { submitTask, clearStatus } from "../../redux/slices/submissionsSlice";

interface CodeEditorProps {
  taskId: Number;
  submissionFormat: string;
  submissionSuccessCallback: () => void;
}

function CodeEditor(props: CodeEditorProps) {
  const dispatch = useAppDispatch();
  const submitStatus = useAppSelector((state) => state.submissions.status);

  const [codeValue, setCodeValue] = React.useState("");

  const handleChange = (value: string | undefined, e: any) => {
    if (value !== undefined) setCodeValue(value);
  };

  const handleSubmit = () => {
    dispatch(
      submitTask({
        taskId: props.taskId,
        submissionFiles: {
          [props.submissionFormat]: btoa(codeValue),
        },
      }),
    );
  };

  React.useEffect(() => {
    if (submitStatus === "succededNewSubmission") {
      dispatch(clearStatus({}));
      props.submissionSuccessCallback();
    }
    // eslint-disable-next-line
  }, [submitStatus]);

  return (
    <Box>
      <Editor
        height="77.5vh"
        defaultLanguage="cpp"
        defaultValue="// Subí tu problema aca"
        onChange={handleChange}
        theme="light"
      />
      <Button variant="contained" onClick={handleSubmit} fullWidth>
        Enviar
      </Button>
    </Box>
  );
}

export default CodeEditor;
