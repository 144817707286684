import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { signUp, clearStatus } from "../redux/slices/userSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../hooks";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://oia.unsam.edu.ar/">
        OIA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.user);
  const [policyAccepted, setPolicyAccepted] = React.useState(false);

  React.useEffect(() => {
    if (userState.isLoggedIn) navigate("/tasks");
    // eslint-disable-next-line
  }, [userState.isLoggedIn, navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    dispatch(
      signUp({
        email: data.get("email")?.toString() || "",
        password: data.get("password")?.toString() || "",
        username: data.get("username")?.toString() || "",
        school: data.get("school")?.toString() || "",
        name: data.get("name")?.toString() || "",
      }),
    );
  };

  React.useEffect(() => {
    if (userState.status === "succededSignUp") {
      dispatch(clearStatus({}));
      navigate("/tasks");
    }
  }, [userState.status, dispatch, navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={userState.status === "failedSignUp"}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoFocus
            />
            <TextField
              error={userState.status === "failedSignIn"}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              error={userState.status === "failedSignIn"}
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              id="email"
            />
            <TextField
              error={userState.status === "failedSignIn"}
              margin="normal"
              required
              fullWidth
              name="school"
              label="Escuela"
              id="school"
            />
            <TextField
              error={userState.status === "failedSignIn"}
              margin="normal"
              required
              fullWidth
              name="name"
              label="Nombre Completo"
              id="name"
            />
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="Acepto los términos y el reglamento del uso del juez"
              value={policyAccepted}
              onChange={() => setPolicyAccepted(!policyAccepted)}
            />
            {userState.status === "failedSignIn" && (
              <Typography color="error">{userState.errorMessage}</Typography>
            )}
            <LoadingButton
              disabled={!policyAccepted}
              type="submit"
              loading={userState.status === "loading"}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Registrarse
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  Ya tenes una cuenta? Iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
