export interface TaskSlice extends TaskList {
  status: string;
  errorMessage: string;
}

export interface Task {
  id: Number;
  title: string;
  name: string;
  submission_format: string[];
  tags: string[];
  attachments: string[];
}

export interface TaskList {
  tasks: Task[];
}

export const compareTasks = (lhs: Task, rhs: Task) =>
  lhs.name.localeCompare(rhs.name);
